/* .App {
  text-align: center;
} */
@media screen and (max-width: 600px) {
  #hid {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}